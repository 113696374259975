import Axios from "axios";
import config from "@/assets/js/config.js";

function onUpLoadImg(token, userid, file) {
  return new Promise((resolve) => {
    let _formdata = new FormData(); //创建form对象
    _formdata.append("file", file);
    Axios.post(
      `${
        config.send_url
      }Api/Upload/UploadImages?userid=${userid}&token=${encodeURIComponent(
        token
      )}`,
      _formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((res) => {
        if (res.data.code != 100) {
          alert(res.data.msg);
          return;
        }
        let imgname = res.data.data.imgname;
        resolve({
          name: imgname,
          size: file.size,
          type: file.type,
          url: `${config.send_url}upload/${imgname}`,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  });
}

export default {
  onUpLoadImg,
};
