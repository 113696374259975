import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import config from "@/assets/js/config";
let send_url = `${config.send_url}MsgHub`;
const connection = new HubConnectionBuilder()
  .withUrl(send_url, {
    withCredentials: false, // 设置为 false，表示不携带凭证
    /*
    transport传输模式,不指定时将会自动判断可用模式

    1、【WebSockets模式】HttpTransportType.WebSockets
    优点-双向通信：支持客户端和服务器之间的双向实时通信，适用于复杂的实时交互场景。
    优点-低延迟：由于持久连接和减少了 HTTP 请求/响应的开销，WebSockets 提供更低的延迟。
    优点-高效：适用于需要高频率消息传输的应用，比如在线游戏、实时聊天等。
    缺点-复杂性：实现和维护可能比 SSE 更复杂，需要处理连接管理、消息格式、数据完整性等问题。
    缺点-协议支持：WebSocket 协议在某些旧版或限制性环境中可能不被支持。
    缺点-资源消耗：长时间保持连接会消耗更多的服务器和客户端资源。

    测试发现：受限于Nginx(默认不支持WebSockets)会产生大量报错日志,所以不要选(除非解决Nginx这层问题,否则不要选这个)

    2、【SSE模式】HttpTransportType.ServerSentEvents
    优点-简洁易用：基于 HTTP 协议，易于实现和使用。浏览器原生支持，通常只需少量配置。
    优点-轻量级：对于简单的单向推送（从服务器到客户端），比 WebSockets 更轻量。
    优点-自动重连：SSE 支持自动重连功能，即使连接中断，也可以自动重新建立连接。
    缺点-单向通信：只支持从服务器到客户端的单向数据流，不支持客户端向服务器发送消息的场景。
    缺点-浏览器兼容性：虽然大部分现代浏览器都支持 SSE，但某些旧版或特殊浏览器可能不支持。
    缺点-效率限制：对于高频率的消息推送或大规模用户并发，可能会受到限制。

    测试发现：暂未发现异常,可以使用

    3、【长轮询模式】HttpTransportType.LongPolling
    优点-兼容性：基于标准的 HTTP 协议，几乎所有浏览器和环境都支持。
    优点-灵活性：可以适应各种服务器端技术和客户端环境。
    缺点-效率低：相比 SSE 和 WebSockets，长轮询效率较低，因为每次轮询都需要重新建立 HTTP 请求。
    缺点-延迟较高：在请求之间的延迟可能较高，尤其是在高并发情况下。
    缺点-服务器负载：可能会对服务器产生较大的负载，因为需要处理频繁的 HTTP 请求。

    测试发现：2个客户端长时间连接时会造成服务器崩溃重启,所以不要选
     */
    transport: HttpTransportType.ServerSentEvents, //传输模式
  })
  .withAutomaticReconnect()
  .build();

connection.onclose(() => {
  console.log("连接已关闭");
});
//尝试连接到服务端
function StartConnection(connectionId) {
  connection
    .start()
    .then(() => {
      console.log("连接成功");
      connectionId(connection.connectionId);
    })
    .catch((error) => {
      console.error("连接错误:", error);
    });
}

export default {
  connection,
  StartConnection,
};
