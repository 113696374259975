<template>
  <SignalrChat :token="$utils.getloc('token')" :userid="us.userid" />
</template>

<script>
import { ref } from "vue";
import SignalrChat from "@/components/SignalrChat/index";
export default {
  components: {
    SignalrChat,
  },
  data() {
    return {
      us: ref(null),
    };
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"));
  },
  methods: {},
};
</script>

<style scoped></style>