<template>
  <div :style="{ width: width }">
    <vue-advanced-chat
      :height="height"
      :theme="theme"
      :multiple-files="multipleFiles"
      :show-add-room="showAddRoom"
      :show-audio="showAudio"
      :show-emojis="showEmojis"
      :emoji-data-source="emojiDataSource"
      :show-files="showFiles"
      :show-reaction-emojis="showReactionEmojis"
      :show-new-messages-divider="showNewMessagesDivider"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :messages="JSON.stringify(messages)"
      :rooms-loaded="roomsLoaded"
      :messages-loaded="messagesLoaded"
      :message-actions="JSON.stringify(messageActions)"
      :text-messages="JSON.stringify(textMessages)"
      :accepted-files="acceptedFiles"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
    />
  </div>
</template>

<script>
import utils from "@/assets/js/utils.js";
import request from "@/assets/js/request.js";
import signalr from "./tool/signalr.js";
import chatUploadImg from "./tool/chatUploadImg.js";

//vue-advanced-chat文档地址(有英无中)：https://github.com/advanced-chat/vue-advanced-chat?tab=readme-ov-file#props-api
import { register } from "vue-advanced-chat";
register();

export default {
  props: {
    //包裹容器宽度
    width: {
      type: String,
      default: "100%",
    },
    //vue-advanced-chat高度
    height: {
      type: String,
      default: "100vh",
    },
    token:{
      type:String,
      default:""
    },
    userid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      roomId: "", //当前房间

      theme: "dark", //主题：light或dark
      multipleFiles: true, //多图片
      showAddRoom: false, //搜索框旁边的按钮
      showAudio: false, //语音功能
      showEmojis: true, //表情功能
      emojiDataSource: `${window.location.origin}/emoji.json`, //表情包地址
      showFiles: true, //发送文件(图片)功能
      acceptedFiles: "image/png,image/jpeg,image/gif", //允许的文件用','隔开(填写'*'为任意文件,图片：image/png,image/jpeg,image/gif 视频：video/mp4)
      showReactionEmojis: false, //每条消息的表情反应
      showNewMessagesDivider: false, //新消息分隔符

      currentUserId: "", //vue-advanced-chat用户唯一标识
      roomsLoaded: true, //左侧房间加载状态
      messagesLoaded: true, //右侧内容消息加载状态
      textMessages: {
        ROOMS_EMPTY: "没有聊天信息",
        ROOM_EMPTY: "没有聊天信息",
        NEW_MESSAGES: "新消息",
        MESSAGE_DELETED: "删除消息",
        MESSAGES_EMPTY: "没有更多消息了",
        CONVERSATION_STARTED: "对话开始于:",
        TYPE_MESSAGE: "输入消息",
        SEARCH: "搜索关键字",
        IS_ONLINE: "在线",
        LAST_SEEN: "上次在线时间",
        IS_TYPING: "正在输入...",
        CANCEL_SELECT_MESSAGE: "取消选择消息",
      }, //语言自定义
      messageActions: [
        // {
        //   name: "replyMessage",
        //   title: "回复",
        // },
        // {
        //   name: "editMessage",
        //   title: "修改消息",
        //   onlyMe: true,
        // },
        // {
        //   name: "deleteMessage",
        //   title: "删除消息",
        //   onlyMe: true,
        // },
        // {
        //   name: "selectMessages",
        //   title: "选择消息",
        // },
      ], //消息内容选项
      rooms: [], //左侧房间列表数据
      messages: [], //消息列表
    };
  },
  created() {
    //connectionId绑定用户
    signalr.StartConnection((connectionId) => {
      request.post(
        "api/SignaUsers/Bind",
        {
          userid: this.userid,
          connectionId,
        },
        (res) => {
          if (res.data.code != 100) {
            return alert(res.data.msg);
          }
          this.currentUserId = res.data.data.currentUserId;
          this.getRoom();
        }
      );
    });

    signalr.connection.on("GotNews", (val) => {
      //console.log(val); //收到新消息提醒
      if (this.roomId == val._id) {
        this.messages.push(val);
      }

      this.getRoom();
    });
  },
  beforeUnmount() {
    signalr.connection.off("GotNews"); //一定要移除监听事件,不然会一直保留(后端触发通知会执行1+n次)
    signalr.connection.stop(); //断开signalr(websocket)链接
  },
  methods: {
    cleanUnreadCount() {
      //消除当前对话房间消息数字
      let rooms = this.rooms;
      rooms.forEach((item) => {
        if (item.roomId == this.roomId) {
          item.unreadCount = 0;
        }
      });
      this.rooms = rooms;
    },
    getRoom() {
      request.post(
        "api/SignaRoom/List",
        {
          userid: this.userid,
        },
        (res) => {
          let data = res.data.data;
          this.rooms = data;
          this.cleanUnreadCount();
        }
      );
    },
    fetchMessages(e) {
      this.messages = [];
      let { room } = e.detail[0];
      this.roomId = room.roomId; //设置当前房间id
      this.messagesLoaded = false;
      //使用setTimeout可以让加载时的动画顺滑
      setTimeout(() => {
        request.post(
          "api/SignaRoomMsg/List",
          {
            userid: this.userid,
            roomId: room.roomId,
          },
          (res) => {
            let data = res.data.data;
            this.messages = data;
            this.cleanUnreadCount();
            this.messagesLoaded = true;
          }
        );
      }, 1000);
    },
    sendMessage(e) {
      // let { roomId, content, files, replyMessage, usersTag } = e.detail[0];
      let { roomId, content, files } = e.detail[0];

      const promises = [];
      if (files) {
        files.forEach((file) => {
          promises.push(
            chatUploadImg.onUpLoadImg(
              this.token,
              this.userid,
              new File([file.blob], `${file.name}.${file.extension}`, {
                type: file.type,
              })
            )
          );
        });
      }
      //不存在异步上传任务则构建一个,避免Promise.all报错
      if (promises.length == 0) {
        promises.push(
          new Promise((resolve) => {
            resolve("");
          })
        );
      }
      //等待所有异步任务完成
      Promise.all(promises)
        .then((responses) => {
          // 所有请求成功完成
          return Promise.all(responses);
        })
        .then((data) => {
          request.post(
            "api/SignaRoomMsg/SendMsg",
            {
              roomId,
              userid: this.userid,
              content,
              files: JSON.stringify(data.filter(Boolean)),
            },
            (res) => {
              if (res.data.code != 100) {
                return alert(res.data.msg);
              }
            }
          );
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
  },
};
</script>